// import {useContext} from 'react'
// import { LanguageContext } from './languageContext'

export default function InnerImg({innerImgSource, innerImgAlt, moreAttribute}) {
  // const { websiteDirection } = useContext(LanguageContext);

  return (
    <>
      <img className="w-3 h-3 sm:w-4 sm:h-4 mb-0.5 mr-0.5 sm:mr-1" src={innerImgSource} alt={innerImgAlt} loading={moreAttribute}/>
    </>
  )
}
