import { Link } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
// import { LanguageContext } from './languageContext';

export default function WaLoAyah() {



  return (


    
    
    <>
{/* 

    <div class="bg-blue-500 sm:bg-green-500 md:bg-yellow-500 lg:bg-red-500 xl:bg-purple-500">
      Responsive Box
    </div> */}

    <div style={{fontFamily: "IBM-Medium"}} className="bg-slate-900 text-slate-200 min-h-screen bg-[url('https://abdullahalmohsen.com/Wallpaper.png')]
      overflow-hidden top-0 left-1/2 bg-no-repeat bg-top before:bg-cover stroke-transparent
      before:w-full before:h-full -z-[1] before:-translate-x-1/2">
        <div className="bg-gradient-to-r from-blue-700 to-indigo-500 h-1 w-full mb-8"/>
          <div className="main py-8">
            <main className="m-auto px-6 max-w-5xl font-IBM" dir="ltr">

            <div className="flex justify-start text-center mb-3">
                <Link to="/" className="mb-2">
                  <div className="text-gray-300 hover:scale-110 transitions-all 
                  font-normal lg:text-sm inline-flex items-start px-3.5 py-1.5
                  rounded group">
                    <div className="inline-flex items-center">
                      <img src="./Assets/Go back.png" className="w-3.5 me-1 aspect-square filter grayscale group-hover:grayscale-0 group-hover:filter-none" alt="Go Back"/>
                    </div>
                    Main Page
                  </div>
                </Link>
              </div>

              <div className="text-center mb-2">
                <h2 className="text-3xl font-bold m-0 mb-3 justify-center hover:scale-110 transition-all">
                    <a href="https://twitter.com/waloayah" rel="noreferrer" target="_blank">
                    Wa Lo Ayah | ولو آية
                    </a></h2>

                    <p className="mt-6 text-right font-normal prose lg:text-lg md:text-base" dir="rtl">
                    "ولو آية" هو مشروع بسيط. عبارة عن حساب في تويتر (X) يقوم بتنزيل صورة لـ آية من القرآن الكريم
                    كل ثلاثة ساعات تقريباً. ما قمت به هو كتابة سكريبت بسيط بإستخدام Python 
                    يقوم بالوصول لحساب تويتر من خلال مفاتيح الـAPI الخاصة بحساب تويتر والتي يمكن استخراجها من <a dir="ltr" target="_blank" rel="noreferrer" className="underline" href="https://developer.twitter.com">{"https://developer.twitter.com/"}</a>
                   <span> ولجعل</span> الحساب يقوم بالنشر بشكل دائم قمت باستخدام خدمات سحابة AWS وايضاً بإنشاء مستخدم جديد داخل حساب AWS وإنشاء مفاتيح الوصول (Access Key) واستخدام اسم حاوية AWS S3. تمت الإستعانة بخدمة من خدمات AWS وهي Lambda function . ولكن قبل ذلك رفعت الصور لحاوية  AWS S3 Bucket
                    وبالإستعانة بخدمة AWS Eventbridge تمت جدولة الحساب ليقوم بالنشر كل ساعتين. يمكن لإي شخص استعمال الكود واستخدام الطريقة التي استخدمتها! لأنشاء مفاتيح الـAPI الخاصة بـ تويتر(X) وطريقة استخدام AWS لعمل المطلوب وتحميل الكود, ينبغي عليك تفقد الروابط التي بالإسفل 👇! <br/><br/>
                    🔴 : أنا غير مسؤول عن أي استعمال خاطئ أو سيء للطريقة المشروحة أو الكود.
                    </p>
                    
                    <p className="mt-9 text-left font-normal prose lg:text-lg md:text-base">
                    "Wa Lo Ayah" is simple project. Which is a Twitter(X) Account that posts Images of Ayah from the Holy Quran every three hours.
                    what I did to post and schedule image posting is create a Python Script, the Python Script will
                    access to Twitter(X) account through Twitter(X) account API tokens which can be generated from <a className="underline" target="_blank" rel="noreferrer" href="https://developer.twitter.com/">https://developer.twitter.com/</a> .
                    To make the account work without running the script locally each time, I depend on AWS Cloud Services. I created AWS Lambda function and used AWS Eventbridge to schedule the posting of images,
                    but before that, In AWS account I created  a new AWS User and then generated an Access Key and then I created AWS S3 Bucket and Uploaded the images to it. AWS Access Key and Bucket names must be labeled in certain variables in the code.
                    By using AWS Eventbridge, I make sure that my Twitter(X) account will post an image every two hours! To generate Twitter(X) tokens and AWS process step by step you should check the links below 👇!
                    <br/><br/>
                    🔴 : I am not responsible for any false or abusive use of the methods I use or the script.
                    </p>


              </div>

              <div className="mx-auto">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-10">
                
                            <a href="https://twitter.com/waloayah" rel="noreferrer" target="_blank" className="delayed-link 
                            bg-gradient-to-bl from-indigo-600 to-sky-600                  
                            rounded-lg ps-1 py-1 pe-1
                            hover:scale-105 transition-all group">
                            <div className="flex flex-row justify-start items-center">
                                <img className="w-7 md:w-8 rounded-full me-4" alt="Wa Lo Ayah | ولو آية" src="./Assets/WaLoAyah.jpeg" loading="lazy"/>
                                <h3 dir="ltr">Wa Lo Ayah | ولو آية</h3>
                            </div>
                            </a>
            
                            <a href="https://github.com/Abdullah-Dev115/ShareRandomImagesTo-X-ByAWS" rel="noreferrer" target="_blank" className="delayed-link 
                            bg-gradient-to-bl from-indigo-600 to-sky-600     
                            rounded-lg ps-1 py-1 pe-1 
                            hover:scale-105 transition-all group">
                            <div className="flex flex-row justify-start items-center">
                                <img className="w-7 md:w-8 rounded-md me-4" alt="Download Code from Github" src="./Assets/Github-white.png" loading="lazy"/>
                                <h3 dir="ltr">Download Code</h3>
                            </div>
                            </a>
            
                            <a href="https://abdullahalmohsen.com/Assets/Post_Random_Images_by_AWS.pdf" rel="noreferrer" target="_blank" className="delayed-link 
                            bg-gradient-to-bl from-indigo-600 to-sky-600     
                            rounded-lg ps-1 py-1 pe-1
                            hover:scale-105 transition-all group">
                            <div className="flex flex-row justify-start items-center">
                                <img className="w-7 md:w-8 rounded-md me-4" alt="PDF Document" src="./Assets/PDF.png" loading='lazy'/>
                                <h3 dir="ltr">AWS Process Steps</h3>
                            </div>
                            </a>
            
                            <a href="https://abdullahalmohsen.com/Generate_X_API_Tokens.pdf" rel="noreferrer" target="_blank" className="delayed-link 
                            bg-gradient-to-bl from-indigo-600 to-sky-600     
                            rounded-lg ps-1 py-1 pe-1 
                            hover:scale-105 transition-all group">
                            <div className="flex flex-row justify-start items-center">
                                <img className="w-7 md:w-8 rounded-md me-4" alt="PDF Document" src="./Assets/PDF.png" loading='lazy'/>
                                <h3 dir="ltr">Generate Twitter(X) API Tokens</h3>
                            </div>
                            </a>
            
            
            
                        </div>
                </div>
            {/* 
            <SectionWrapper columns={2} moreStyling={'mx-9'}>

              <SectionItems link={"https://twitter.com/waloayah"}
              itemTitle={"Wa Lo Ayah | ولو آية"} imgSource={"./Assets/WaLoAyah.jpeg"} imgAlt={"Wa Lo Ayah Logo"} moreStylingImg={"rounded-full"}/>

              <SectionItems link={"https://github.com/Abdullah-Dev115/ShareRandomImagesTo-X-ByAWS"}
              itemTitle={"Download Code"} imgSource={"./Assets/Github-W.png"} moreStylingImg={"Github"} imgAlt={"Pyqt"}/>

              <SectionItems link={"https://github.com/Abdullah-Dev115/ShareRandomImagesTo-X-ByAWS.pdf"}
              itemTitle={"AWS Process Steps"} imgSource={"./Assets/PDF.png"} imgAlt={"PDF"}/>

              <SectionItems link={"https://abdullahalmohsen.com/Generate_X_API_Tokens.pdf"}
              itemTitle={"Generate Twitter(X) API Tokens"} imgSource={"./Assets/PDF.png"} imgAlt={"PDF"}/>

            </SectionWrapper> */}

                




            </main>      
          </div>
      </div>

    </>
  )
}
