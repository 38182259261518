import { Helmet } from "react-helmet"
export default function HeadTags() {
  return (
    <>
    <Helmet>
        <meta charSet="UTF-8" />
        <meta name="description" content="A Software Engineering Student in Prince Sattam bin abdulaziz university" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Abdullah Al-Mohsen" />
        <title>Abdullah Al-Mohsen | عبدالله المحسن</title>
        <link rel="stylesheet" href="style.css" />
        <link rel="icon" href="./Assets/Icon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="./Assets/Icon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="./Assets/Icon-16x16.png" />
        <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="./Assets/Icon-AppleTouch.png" />
    </Helmet>
    </>
  )
}
