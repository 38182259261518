import { useTranslation } from 'react-i18next';
// import { useContext } from 'react';
// import { LanguageContext } from './languageContext';

export default function MiniSection({columns, children}) {

  const { t } = useTranslation();
  // const { websiteDirection } = useContext(LanguageContext);

  
  // useEffect(() => {
  //   i18n.changeLanguage("ar")
  // })
  return (
    <>
    <div className="mb-20">
        <div>
            <h3 className="font-semibold text-base sm:text-lg text-gray-500 leading-none">{t("Developer Skills that I want to learn")}</h3>
            <hr className="h-px my-4 bg-indigo-600 border-0"/>
        </div>

        <div className={`grid grid-cols-2 gap-2 mt-4`}>
            {children} 
        </div>
    </div>
    </>
  )
}
