
export default function CodeStatement() {
  return (
    <>

        {/* Code Statment */}
        <div className="text-center mt-8 mb-2">
            <p dir='ltr'><code className="bg-slate-800 py-0.5 px-1 rounded">if success() == True: celebrate()
            <br/>while success() == False: try_again()<br/>be_awesome()</code><br/></p>
        </div>
        {/* Code Statment End */}
    
    </>
  )
}
