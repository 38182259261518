import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { LanguageContext } from './languageContext';

export default function ContactSection() {

    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const marginLorR = language === "ar" ? "ml-2" : "mr-2"
  
    // useEffect(() => {
    //   i18n.changeLanguage("ar")
    // })
  return (
    <>
    <div className='py-10'>
        <div className="container mx-auto">
            <h2 className='flex items-center font-extrabold text-2xl'>
              <img src="./Assets/Contact.png" alt="Contact" loading="lazy" decoding="async" className={`w-8 h-8 ${marginLorR}`}/>
              {t("Contact Me")}
            </h2>
            <hr className="h-px my-4 border-0 bg-indigo-600"/>

            <div className='bg-gray-800 shadow-md flex-col rounded-xl p-4 sm:p-6 lg:p-8'>
                <form action='https://formspree.io/f/xdoqgkwk' method='post'>
                    <div className='grid gap-4 lg:gap-6'>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6'>
                            <div>
                                <label for="FirstName" className="block text-sm text-white font-medium mb-1">{t("First Name")}</label>
                                <input type="text" name="FirstName" id="FirstName" className="
                                w-full px-2.5 py-2 border rounded-md focus:border-blue-500 bg-slate-800 text-slate-300" placeholder={t("Ahmed")} required=""/>
                            </div>
                            
                            <div>
                                <label for="FirstName" className="block text-sm text-white font-medium mb-1">{t("Last Name")}</label>
                                <input type="text" name="LastName" id="LastName" className="
                                w-full px-2.5 py-2 border rounded-md focus:border-blue-500 bg-slate-800 text-slate-300" placeholder={t("Mohammed")} required=""/>
                            </div>



                        </div>

                        <div className='grid grid-rows-1 sm:grid-rows-2 gap-4 lg:gap-4'>

                        <div>
                                <label for="Contact-Email" className="block text-sm text-white font-medium mb-1">{t("Email")}</label>
                                <input type="email" name="Contact-Email" id="Contact-Email" className="
                                w-full px-2.5 py-2 border rounded-md focus:border-blue-500 bg-slate-800 text-slate-300" placeholder="email@example.com" required=""/>
                            </div>

                            <div>
                                <label for="Phone-contact" className="block text-sm text-white font-medium mb-1">{t("Phone Number")}</label>
                                <input type="tel" name="Phone-contact" id="Phone-contact" className="
                                w-full px-2.5 py-2 border rounded-md focus:border-blue-500 bg-slate-800 text-slate-300" placeholder="05XXXXXXXX" required=""/>
                            </div>
                            
                            <div>
                                <label for="Message" className="block text-sm text-white font-medium mb-1">{t("Message")}</label>
                                <textarea id="Message" name="Message" rows="4" className="w-full px-3 py-2 border rounded-md focus:border-blue-500 bg-slate-800 text-slate-300" placeholder={t("How can I help you?")} required></textarea>
                            </div>

                        </div>

                        <div className="mt-4 grid">
                                <button type="submit" class=" justify-center items-center py-3 px-3 
                                text-center lg:text-base text-sm text-white font-medium rounded-md bg-indigo-600 hover:bg-indigo-700 
                                focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-white transition">{t("Send")}</button>                    
                        </div>

                        <div className="mt-q text-center">
                            <p className="text-sm text-gray-400">{t("I Will try to responed in the fastest I can")}</p>
                        </div>
                        
                    </div>
                </form>
            </div>

            <footer>
                <hr class="h-px my-4 bg-indigo-600 border-0"/>
                <p class="text-slate-300 text-sm mb-2 text-center">
                    {t("Copyright © 2024")}
                </p>
                <p class="text-slate-300 text-sm text-center">
                    <b>{t("Abdullah Al-Mohsen")}</b>
                </p>
            </footer>

        </div>


          

    </div>
    </>
  )
}
