import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../languageContext';


export default function SwitchLanguage() {

    const { language, setLanguage } = useContext(LanguageContext);
    const { t } = useTranslation();
  
    const handleLanguageChange = () => {
      const newLanguage = language === 'ar' ? 'en' : 'ar';
      setLanguage(newLanguage);
    };
    
  return (
    <>
                
    <div className="text-slate-500 dark:text-slate-400 justify-center text-center mt-3 mb-20">
        <p>{t("Hello and Welcome in my website!")}</p>

        <label class="relative inline-flex cursor-pointer items-center mt-3">
            <input type="checkbox" checked={language === "en"} onChange={handleLanguageChange} class="peer sr-only" />
            <div dir='rtl' class=" flex h-7 items-center gap-7 rounded-full px-5 after:absolute after:right-1 after: after:h-6 after:w-16 after:rounded-xl after:bg-white/40 after:transition-all after:content-[''] peer-checked:bg-slate-700 peer-checked:after:-translate-x-full peer-focus:outline-none bg-slate-700 text-xs text-white">
                <span>العربية</span>
                    <span>English</span>
                </div>
        </label>

    </div>

    </>
  )
}
