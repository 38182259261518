import { Link } from "react-router-dom";

export default function WinshutScheduler() {
  return (
    <>


      <div style={{fontFamily: "IBM-Medium"}} className="bg-slate-900 text-slate-200 min-h-screen bg-[url('https://abdullahalmohsen.com/Wallpaper.png')]
      overflow-hidden top-0 left-1/2 bg-no-repeat bg-top before:bg-cover stroke-transparent
      before:w-full before:h-full -z-[1] before:-translate-x-1/2">
        <div className="bg-gradient-to-r from-blue-700 to-indigo-500 h-1 w-full mb-8"/>
          <div className="main py-8">
            <main className="m-auto px-6 max-w-5xl font-IBM" dir="ltr">

              <div className="flex justify-start text-center mb-3">
                <Link to="/" className="mb-2">
                  <div className="text-gray-300 hover:scale-110 transitions-all 
                  font-normal lg:text-sm inline-flex items-start px-3.5 py-1.5
                  rounded group">
                    <div className="inline-flex items-center">
                      <img src="./Assets/Go back.png" className="w-3.5 me-1 aspect-square filter grayscale group-hover:grayscale-0 group-hover:filter-none" alt="Go Back" loading="lazy"/>
                    </div>
                    Main Page
                  </div>
                </Link>
              </div>

              <h2 className="text-3xl font-extrabold m-0 mb-3 text-center justify-center">My first project ever!</h2>
                <p className="mt-6 text-left font-normal"><b>Windows Shutdown Scheduler</b> is my first project,
                it is functionality is simple! In Windows opreating system, If you want to shutdown your machine after certain time...for example,
                if you want to shutdown your machine after 7 hours 
                you need to open the command-prompt (CMD) write the command 
                "shutdown /s /t 25200" , yes! you need to open the CMD then write the command and in the end of it the
                number of seconds! yes, you need to open the calculator and apply the formula (7x60x60) to turn the 7 hours into seconds...what a mess! with <b>Windows shutdown scheduler</b>
                you can schedule the shutdown order easily with simple UI 😉.</p>

                <p dir="rtl" className="mt-6 text-right mb-4 font-normal">
                    برنامج <b>Windows Shutdown Scheduler</b> هو أول مشروع لي, طريقة عمله بسيطة! في نظام ويندوز, إذا أردت إيقاف تشغيل جهازك بعد وقتٍ معين...على سبيل المثال, إذا أردت إيقاف تشغيل جهازك بعد 7 ساعات فلابد أن تفتح موجه الأوامر (CMD) 
                    وتكتب الأمر التالي "shutdown /s /t 25200" , صحيح! لابد ايضاً أن تستعمل الحاسبة وأن تستخدم المعادلة التالية (7x60x60) لتحويل ال7 ساعات إلى ثواني...صداااع! مع <b>Windows Shutdown Scheduler</b> تستطيع جدولة إيقاف التشغيل بسهولة مع واجهة رسومية بسيطة 😉.
                </p>
                
                <div className="mt-8 bg-slate-700 mx-auto py-2 px-1 rounded-xl bg-opacity-95 text-center">
                  <p>Note: this software will not receive any updates. Because the functionality of this software will be integrated with my next project 
                      <code className="text-base"><b>{" CMD-UI"}</b></code>
                  </p>
                  <p dir="rtl">ملاحظة: هذا البرنامج لن يتلقى أي تحديثات. لأن آلية هذا البرنامج سيتم دمجها مع مشروعي القادم <code><b>CMD-UI</b></code> </p>
                </div>
                <img className="block sm:hidden md:hidden" src="./Assets/Winshut-Compare Mobile.png" alt="Mobile Ver" loading='lazy'/>
                <img className="hidden sm:block" src="./Assets/Winshut Compare.png" alt="Else Ver" loading='lazy'/>
                
                <div class="flex justify-center text-center lg:text-start me-3 mb-12">
                  <div>
                      <a href="https://github.com/Abdullah-Dev115/Windows-Shutdown-Scheduler" class="delayed-link me-2 inline-block">
                          <div class="bg-gradient-to-bl from-cyan-700 to-blue-400
                            hover:bg-gray-200 hover:scale-110 transition-all 
                              text-sm inline-flex items-start px-4 py-3 rounded-lg">
                              <div class="inline-flex items-center">
                                  <img class="w-4 me-2 aspect-square filter grayscale group-hover:grayscale-0 group-hover:filter-none" alt="Github" src="./Assets/Github-W.png" loading='lazy'/>
                              </div>
                              Download executable file (exe) and Code
                          </div>
                      </a>
                  </div>
              </div>

            </main>      
          </div>
      </div>
    </>
  )
}
