import { useState , useContext } from 'react';
import { Link } from 'react-router-dom'
import { LanguageContext } from './languageContext';
import { useTranslation } from 'react-i18next';
import Section from './Section';
import SectionItems from './SectionItems';
import InnerImg from './InnerImg';
import MiniSection from './MiniSection';
import ContactSection from './ContactSection';
import SectionWrapper from './SectionWrapper';
import Introducing from './Introducing/Introducing';

export default function BodyAndHeader() {

  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const marginLorR = language === "ar" ? "mr-2" : "ml-2"

  const [selectedOS, setSelectedOS] = useState('all')

  const softwaresList = [
    {
      link: "https://code.visualstudio.com/",
      itemTitle: "VS Code",
      imgSource: "./Assets/VS Code.png",
      imgAlt: "VS Code",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.jetbrains.com/pycharm/download",
      itemTitle: "PyCharm",
      imgSource: "./Assets/PyCharm.png",
      imgAlt: "PyCharm",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://obsproject.com",
      itemTitle: "OBS Studio",
      imgSource: "./Assets/OBS.png",
      imgAlt: "OBS Studio",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.voidtools.com/downloads/",
      itemTitle: "Everything",
      imgSource: "./Assets/Everything.png",
      imgAlt: "Everything",
      os: ["Windows"],
      moreAttribute:"lazy"
    },
    {
      link: "https://nektony.com/funter",
      itemTitle: "Funter",
      imgSource: "./Assets/Funter.png",
      imgAlt: "Funter",
      os: ["MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://rectangleapp.com/",
      itemTitle: "Rectangle",
      imgSource: "./Assets/Rectangle.png",
      imgAlt: "Rectangle",
      os: ["MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.macupdate.com/app/mac/62593/monitorcontrol",
      itemTitle: "Monitor Control",
      imgSource: "./Assets/Monitor control.png",
      imgAlt: "Monitor Control",
      os: ["MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.raycast.com/",
      itemTitle: "Raycast",
      imgSource: "./Assets/reycast.png",
      imgAlt: "Raycast",
      os: ["MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://freemacsoft.net/appcleaner/",
      itemTitle: "AppCleaner",
      imgSource: "./Assets/App Cleaner.png",
      imgAlt: "AppCleaner",
      os: ["MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://maccy.app/",
      itemTitle: "Maccy",
      imgSource: "./Assets/Maccy.png",
      imgAlt: "Maccy",
      os: ["MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://apphousekitchen.com/pricing/",
      itemTitle: "AlDente",
      imgSource: "./Assets/AlDente.png",
      imgAlt: "AlDente",
      os: ["MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.winehq.org/",
      itemTitle: "Wine Stable",
      imgSource: "./Assets/Wine Stable.png",
      imgAlt: "Wine Stable",
      os: ["MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://apps.microsoft.com/detail/XP89DCGQ3K6VLD?hl=en-US&gl=US",
      itemTitle: "MS PowerToys",
      imgSource: "./Assets/Powertoys.png",
      imgAlt: "Microsoft PowerToys",
      os: ["Windows"],
      moreStylingImg: 'rounded-md',
      moreAttribute:"lazy"
    },
    {
      link: "https://www.marticliment.com/wingetui/",
      itemTitle: "WingetUI",
      imgSource: "./Assets/wingetuistore.icon.png",
      imgAlt: "Microsoft PowerToys",
      os: ["Windows"],
      moreStylingImg: 'rounded-md',
      moreAttribute:"lazy"
    },
    {
      link: "https://pcmanager.microsoft.com/",
      itemTitle: "MS PC Manager",
      imgSource: "./Assets/PC Manager.png",
      imgAlt: "Microsoft PC Manager",
      os: ["Windows"],
      moreStylingImg: 'rounded-md',
      moreAttribute:"lazy"
    },
    {
      link: "https://winaero.com/winaero-tweaker/",
      itemTitle: "Winaero Tweaker",
      imgSource: "./Assets/Winaero.png",
      imgAlt: "Winaero Tweaker",
      os: ["Windows"],
      moreStylingImg: 'rounded-md',
      moreAttribute:"lazy"
    },
    {
      link: "https://www.virtualbox.org/",
      itemTitle: "VirtualBox",
      imgSource: "./Assets/VirtualBox.png",
      imgAlt: "VirtualBox",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.videolan.org/",
      itemTitle: "VLC Media Player",
      imgSource: "./Assets/VLC.png",
      imgAlt: "VLC Media Player",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.google.com/drive/download/",
      itemTitle: "Google Drive Desktop",
      imgSource: "./Assets/Google drive.png",
      imgAlt: "Google Drive",
      os: ["Windows", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://kagi.com/orion/",
      itemTitle: "Orion Browser",
      imgSource: "./Assets/Orion.png",
      imgAlt: "Orion Browser",
      os: ["MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://arc.net/",
      itemTitle: "Arc Browser",
      imgSource: "./Assets/Arc.png",
      imgAlt: "Arc Browser",
      os: ["Windows", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.google.com/chrome/",
      itemTitle: "Google Chrome",
      imgSource: "./Assets/Chrome.png",
      imgAlt: "Google Chrome",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.microsoft.com/en-us/edge/download",
      itemTitle: "Microsoft Edge",
      imgSource: "./Assets/Edge.png",
      imgAlt: "Microsoft Edge",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://nordpass.com/download/",
      itemTitle: "Nordpass Desktop",
      imgSource: "./Assets/Nordpass.png",
      imgAlt: "Nordpass Desktop",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://bitwarden.com/download/",
      itemTitle: "Bitwarden",
      imgSource: "./Assets/Bitwarden.png",
      imgAlt: "Bitwarden",
      os: ["Windows", "Linux", "MacOS"],
      moreStylingImg: "rounded-md",
      moreAttribute:"lazy"
    },
    {
      link: "https://zoom.us/download",
      itemTitle: "Zoom",
      imgSource: "./Assets/Zoom.png",
      imgAlt: "Zoom Desktop",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "http://apple.com/itunes",
      itemTitle: "Apple iTunes",
      imgSource: "./Assets/Itunes.png",
      imgAlt: "iTunes",
      os: ["Windows"],
      moreAttribute:"lazy"
    },
    {
      link: "https://support.apple.com/en-sa/103232",
      itemTitle: "iCloud",
      imgSource: "./Assets/iCloud.png",
      imgAlt: "Icloud",
      os: ["Windows"],
      moreAttribute:"lazy"
    },
    {
      link: "https://apps.microsoft.com/detail/9NP83LWLPZ9K?hl=en-us&gl=US",
      itemTitle: "Apple Devices",
      imgSource: "./Assets/Apple Devices.png",
      imgAlt: "Apple Devices",
      os: ["Windows"],
      moreAttribute:"lazy"
    },
    {
      link: "http://3u.com",
      itemTitle: "3U Tools",
      imgSource: "./Assets/3U.png",
      imgAlt: "3U Tools",
      os: ["Windows", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.win-rar.com/",
      itemTitle: "Winrar",
      imgSource: "./Assets/Winrar.png",
      imgAlt: "Winrar",
      os: ["Windows", "Linux", "MacOS"],
      moreStylingImg: "rounded-md",
      moreAttribute:"lazy"
    },
    {
      link: "https://www.office.com/",
      itemTitle: "MS Office",
      imgSource: "./Assets/Office.png",
      imgAlt: "MS Office",
      os: ["Windows", "MacOS"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.memuplay.com/",
      itemTitle: "Memu Play",
      imgSource: "./Assets/Memu.png",
      imgAlt: "Memu Play",
      os: ["Windows"],
      moreAttribute:"lazy"
    },
    {
      link: "https://www.internetdownloadmanager.com/",
      itemTitle: "Internet Download Manager",
      imgSource: "./Assets/IDM.png",
      imgAlt: "Internet Download Manager",
      os: ["Windows"],
      moreAttribute:"lazy"
    },
    {
      link: "https://filezilla-project.org/",
      itemTitle: "Filezila",
      imgSource: "./Assets/Filezila.png",
      imgAlt: "Filezila",
      os: ["Windows", "Linux", "MacOS"],
      moreAttribute:"lazy"
    }

  ];

  const filteredPcSoftwares = softwaresList.filter((software) => {
    if(selectedOS === 'all'){
      return true
    }
    return software.os.includes(selectedOS === 'windows' ? 'Windows' : 'MacOS')
  })

  const handleOSChange = (event) => {
    setSelectedOS(event.target.value)
  }



  return (
    <>
      <div style={{fontFamily: "IBM-Medium"}} className="bg-slate-900 text-slate-200 min-h-screen bg-[url('https://abdullahalmohsen.com/Wallpaper.png')]
      overflow-hidden top-0 left-1/2 bg-no-repeat bg-top before:bg-cover stroke-transparent
      before:w-full before:h-full -z-[1] before:-translate-x-1/2">

        <div className="bg-gradient-to-r from-blue-700 to-indigo-500 h-1 w-full mb-8"/>
        <div className="main py-8">
          <main className="m-auto px-6 max-w-5xl">

            <Introducing/>

            {/* Personal Projects Section */}
            <Section columns={1} sectionMarginBottom={20} sectionTitle={t('My Personal Projects')} imgSource={'Assets/Projects.png'} imgAlt={'Projects'}>

              <SectionItems link={"#"} itemTitle={t("CMD-UI (Prototype) : coming soon...")} imgSource={"./Assets/CMD-UI.png"} imgAlt={"CMD-UI"}>
                  <InnerImg innerImgSource="./Assets/Windows 2.png" innerImgAlt={"Windows"}/>
              </SectionItems>

              <Link to="/WaLoAyah">
                <SectionItems link={"https://abdullahalmohsen.com/PostByAWS.html"} itemTitle={"Wa Lo Ayah | ولو آية"} imgSource={"./Assets/WaLoAyah.jpeg"} imgAlt={""} moreStylingImg={"rounded-2xl"}>
                    <InnerImg innerImgSource="./Assets/AWS1.png" innerImgAlt={"AWS"}/>
                    <InnerImg innerImgSource="./Assets/Github-white.png" innerImgAlt={"Github"}/>
                    <InnerImg innerImgSource="./Assets/Python.png" innerImgAlt={"Python"}/>
                </SectionItems>
              </Link>
              
              <SectionItems link={"https://github.com/Abdullah-Dev115/Post-Random-Images-from-folder-on-X"} itemTitle={t(`Post Random Images from folder on X`)} imgSource={"./Assets/Python Script.png"} imgAlt={"Python Script"} moreAttribute="lazy">
                  <InnerImg innerImgSource="./Assets/Github-white.png" innerImgAlt={"Github"}/>
                  <InnerImg innerImgSource="./Assets/Python.png" innerImgAlt={"Python"}/>
              </SectionItems>
              
              <Link to="/WinshutScheduler">
                <SectionItems link={"Winshut-scheduler.html"} itemTitle={`Windows Shutdown Scheduler`} imgSource={"./Assets/WinShut Schedular.png"} imgAlt={"Windows Shutdown Scheduler"} moreAttribute="lazy">
                    <InnerImg innerImgSource="./Assets/Windows 2.png" innerImgAlt={"Windows"} moreAttribute="lazy"/>
                </SectionItems>
              </Link>


            </Section>
            {/* Personal Projects Section End */}
              
            {/* Contributions Section  */}
            <Section columns={1} sectionMarginBottom={20} sectionTitle={t('Contributions')} imgSource={'Assets/jigsaw.png'} imgAlt={'jigsaw'} moreAttribute={"lazy"}>

              <SectionItems link={"https://github.com/marticliment/WingetUI/blob/main/src/wingetui/Assets/Data/Translators.json"}
              itemTitle={t("WingetUI : Contributing in Arabic translation")} imgSource={"./Assets/wingetuistore.icon.png"}
              moreAttribute={"lazy"} imgAlt={"WingetUI"} contribution={<span class="text-slate-600 flex items-center m-auto">{t("Name: Abdullah-Dev115")}</span>}/>

              <SectionItems link={"https://localazy.com/p/orion-browser"}
              itemTitle={t("Orion Browser : Contributing in Arabic translation")} imgSource={"./Assets/Orion.png"}
              moreAttribute={"lazy"} imgAlt={"Orion-Browser"} contribution={<span class="text-slate-600 flex items-center m-auto">{t("Name: Abdullah-Dev115")}</span>}/>
            
            </Section>
            {/* Contributions Section End */}

            {/* Developer Skills Section */}
            <Section columns={2} sectionMarginBottom={3} sectionTitle={t('Developer Skills')}
            addtionToTitle={<small class={`${marginLorR} font-semibold text-gray-500 leading-snug block sm:inline text-base sm:text-xl`}>{t("that I learned and willing to learn more")}</small>}
            imgSource={'Assets/Developer.png'} imgAlt={'Developer'} moreAttribute={"lazy"}>

              <SectionItems link={"https://www.python.org/"}
              itemTitle={"Python"} imgSource={"./Assets/Python.png"} imgAlt={"Python"}  moreAttribute={"lazy"}/>

              <SectionItems link={"https://doc.qt.io/qtforpython-6/"}
              itemTitle={"PyQt6"} imgSource={"./Assets/Pyqt.png"} moreStylingImg={"rounded-md"} imgAlt={"Pyqt"} moreAttribute={"lazy"}/>

              <SectionItems link={"https://www.java.com/en/download/"}
              itemTitle={"Java"} imgSource={"./Assets/Java.png"} imgAlt={"Java"} moreAttribute={"lazy"}/>

              <SectionItems link={"https://www.w3schools.com/html/"}
              itemTitle={"HTML"} imgSource={"./Assets/HTML5.png"} imgAlt={"HTML5"} moreAttribute={"lazy"}/>

              <SectionItems link={"https://www.w3schools.com/css/"}
              itemTitle={"CSS"} imgSource={"./Assets/CSS.png"} imgAlt={"CSS"} moreAttribute={"lazy"}/>

              <SectionItems link={"https://tailwindcss.com/docs/installation"}
              itemTitle={"Tailwind CSS"} imgSource={"./Assets/Tailwind CSS.png"} moreStylingImg={"rounded-md"}  imgAlt={"TailwindCSS"} moreAttribute={"lazy"}/>

              <SectionItems link={"https://www.w3schools.com/js/default.asp"}
              itemTitle={"Javascript"} imgSource={"./Assets/JavaScript.png"} moreStylingImg={"rounded-md"} imgAlt={"Javascript"} moreAttribute={"lazy"}/>

              <SectionItems link={"https://react.dev"}
              itemTitle={"React JS"} imgSource={"./Assets/React Js.png"} imgAlt={"ReactJS"} moreAttribute={"lazy"}/>

                
            </Section>

            <MiniSection>

              <SectionItems link={"https://www.electronjs.org/"}
              itemTitle={"Electron JS"} imgSource={"./Assets/Electron js.svg"} imgAlt={"Electron JS"} moreAttribute={"lazy"}/>

              <SectionItems link={"https://www.swift.org/"}
              itemTitle={"Swift"} imgSource={"./Assets/Swift.png"} imgAlt={"Swift"} moreAttribute={"lazy"}/>

            </MiniSection>
            {/* Developer Skills Section End */}

            {/* Operating Systems Section */}
            <Section columns={1} sectionMarginBottom={20} sectionTitle={t('Operating Systems')} imgSource={'Assets/Operating Systems.png'} imgAlt={'Operating Systems'}
            moreAttribute={"lazy"}
            addtionToTitle={<small class={`${marginLorR} font-semibold text-gray-500 leading-snug block sm:inline text-base sm:text-xl`}>{t("I've used and still use!")}</small>}>
              
              <SectionItems itemTitle={t("MacOS : Sonoma (Newest)")} imgSource={"./Assets/Apple.png"} imgAlt={"Apple"} moreAttribute={"lazy"}/>

              <SectionItems itemTitle={t("Windows: XP --> 7 --> 8 --> 8.1 --> 10 (2008 to present)")} imgSource={"./Assets/Windows.png"} imgAlt={"Windows"} moreAttribute={"lazy"}/>

              <SectionItems itemTitle={"Kali Linux (VM)"} imgSource={"./Assets/Kali Linux.png"} imgAlt={"Kali Linux"} moreAttribute={"lazy"}/>

            </Section>
            {/* Operating Systems Section End */}

            {/* PC Software Section */}

            <Section columns={1} columnsMd={1} sectionTitle={t('PC Softwares')} imgSource={'Assets/PC.png'} imgAlt={'PC Softwares'}
            moreAttribute={"lazy"} 
            addtionToTitle={<small class={`${marginLorR} font-semibold text-gray-500 leading-snug block sm:inline text-base sm:text-xl`}>{t("that I use")}</small>}>
              
              <select className='bg-slate-800 border border-gray-400 focus:border-blue-500 placeholder-gray-400 text-white focus:ring-blue-500
              w-full p-1.5 block text-sm text-center rounded-lg'
              onChange={handleOSChange} value={selectedOS}>
                
                  <option value="all">{t("All OS")}</option>
                  <option value="windows">{t("Windows only")}</option>
                  <option value="macos">{t("MacOS only")}</option>

              </select>



            <SectionWrapper>

              {filteredPcSoftwares.map((software, id) => (

                  <SectionItems 
                    key={id}
                    link={software.link}
                    itemTitle={software.itemTitle} 
                    imgSource={software.imgSource} 
                    imgAlt={software.imgAlt}
                    moreStylingImg={software.moreStylingImg}
                    moreAttribute={software.moreAttribute}
                    >

                      {software.os.includes('Windows') && <InnerImg innerImgSource="./Assets/Windows 2.png" innerImgAlt="Windows" moreAttribute={"lazy"}/>}
                      {software.os.includes('Linux') && <InnerImg innerImgSource="./Assets/Linux.png" innerImgAlt="Linux" moreAttribute={"lazy"}/>}
                      {software.os.includes('MacOS') && <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt="MacOS" moreAttribute={"lazy"}/>}

                  </SectionItems>


                ))}

            </SectionWrapper>

            </Section>
            {/* PC Software Section End */}

            {/* Mobile Apps Section */}
            <Section columns={2} columnsMd={3} sectionMarginBottom={20} sectionTitle={t('Mobile Apps')} imgSource={'Assets/Mobile.png'} imgAlt={'PC Softwares'}moreAttribute={"lazy"}
            addtionToTitle={<small class={`${marginLorR} font-semibold text-gray-500 leading-snug block sm:inline text-base sm:text-xl`}>{t("that are good and I depend on")}</small>}>
            
              <SectionItems link={"https://nordpass.com/"} itemTitle={"Nordpass"} imgSource={"./Assets/Nordpass.png"} imgAlt={"Nordpass"} moreAttribute={"lazy"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>

              <SectionItems link={"https://bitwarden.com/download/"}
              itemTitle={"Bitwarden"} imgSource={"./Assets/Bitwarden.png"} imgAlt={"Bitwarden"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://www.notion.so/mobile"} itemTitle={"Notion"} imgSource={"./Assets/Notion.png"} imgAlt={"Notion"} moreAttribute={"lazy"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://steps.app/"} itemTitle={"StepsApp"} imgSource={"./Assets/Stepsapp.webp"} imgAlt={"StepsApp"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://sparkmailapp.com/"} itemTitle={"Spark Mail"} imgSource={"./Assets/Spark.webp"} imgAlt={"Spark Mail"}  moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://apps.apple.com/us/app/arc-search-find-it-faster/id6472513080"} itemTitle={"Arc Search"} imgSource={"./Assets/Arc Search.png"} imgAlt={"Arc Search"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>

                          
              <SectionItems link={"https://apps.apple.com/us/app/apple-store/id375380948"} itemTitle={"Apple Store"} imgSource={"./Assets/Apple Store.webp"} imgAlt={"Apple Store"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://www.greatquran.net/"} itemTitle={"القرآن العظيم"} imgSource={"./Assets/Great Quran.webp"} imgAlt={"Great Quran"} moreStylingImg={"rounded-md"} moreAttribute={"lazy"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>

            
              <SectionItems link={"https://mobile.yahoo.com/weather"} itemTitle={"Yahoo Weather"} imgSource={"./Assets/Yahoo Weather.png"} imgAlt={"Yahoo Weather"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://realbyteapps.com/"} itemTitle={"Money Manager"} imgSource={"./Assets/Money Manager.webp"} imgAlt={"Money Manager"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://apps.apple.com/sa/app/crate-pro-currency-converter/id909956791?l=ar"} itemTitle={"cRate Pro"} imgSource={"./Assets/cRate.webp"} moreAttribute={"lazy"} imgAlt={"cRate Pro"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://imac.cc/"} itemTitle={"تحويل التاريخ"} imgSource={"./Assets/Date Converter.webp"} imgAlt={"تحويل التاريخ"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://athkarapp.com/"} itemTitle={"Athkar - أذكار"} imgSource={"./Assets/Athkar.webp"} imgAlt={"Athkar - أذكار"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://www.watn.sa/"} itemTitle={"وطن"} imgSource={"./Assets/Watn.webp"} imgAlt={"وطن"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://downdetector.com/about-us/"} itemTitle={"Downdetector"} imgSource={"./Assets/DownDetector.webp"} imgAlt={"Down Detector"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://apps.apple.com/app/id1281374098"} itemTitle={"Unzip"} imgSource={"./Assets/Unzip.png"} imgAlt={"Unzip"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://www.saudiexchange.sa/#appDtl"} itemTitle={"Saudi Exchange"} imgSource={"./Assets/Saudi Exchange.png"} moreAttribute={"lazy"} imgAlt={"Saudi Exchange"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://smartapp.tuya.com/tuyasmart/"} itemTitle={"Tuya Smart"} imgSource={"./Assets/Tuya Smart.png"} imgAlt={"Tuya Smart"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
                          
              <SectionItems link={"https://soum.sa/"} itemTitle={"Soum |  سوم"} imgSource={"./Assets/Soum.png"} imgAlt={"Soum | سوم"} moreStylingImg={"rounded-md"} moreAttribute={"lazy"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
                  <InnerImg innerImgSource="./Assets/Android.png" innerImgAlt={"Android"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://ncalc-app.web.app/"} itemTitle={"NCalc fx"} imgSource={"./Assets/NCalc.png"} imgAlt={"NCalc fx"} moreAttribute={"lazy"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://apps.apple.com/us/app/web-inspector/id1584825745"} itemTitle={"Web Inspector"} imgSource={"./Assets/Web Inspector.png"} imgAlt={"Web Inspector"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>
            
              <SectionItems link={"https://apps.apple.com/us/app/pipifier/id1234771095"} itemTitle={"PiPifier"} imgSource={"./Assets/PiPifier.png"} imgAlt={"PiPifier"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}>
                  <InnerImg innerImgSource="./Assets/Apple.png" innerImgAlt={"iOS"} moreAttribute={"lazy"}/>
              </SectionItems>

            
            </Section>
            {/* Mobile Apps Section END */}

            {/* Youtube Videos Section */}
            {/* <Section columns={1} columnsMd={1} sectionMarginBottom={20} sectionTitle={t('Youtube Videos')} imgSource={'Assets/play.png'} imgAlt={'PC Softwares'}
            >

              <SectionItems link={"https://www.youtube.com/watch?v=Q-zGfW1T5MI"} itemTitle={"5 تطبيقات رهيبة لنظام الماك !"} 
              direction={"rtl"} imgSource={"./Assets/Youtube.png"} imgAlt={"Youtube Icon"}/>


            </Section> */}
            {/* Youtube Videos Section END */}

            {/* My Writings Section */}
            <Section columns={1} columnsMd={1} sectionMarginBottom={20} sectionTitle={t('My Writings')} imgSource={'Assets/desktop-computer.png'} imgAlt={'PC Softwares'}
            moreAttribute={"lazy"}
            addtionToTitle={<small class={`${marginLorR} font-semibold text-gray-500 leading-snug block sm:inline text-base sm:text-xl`}>{t("that may interest you!")}</small>}>

              <SectionItems link={"https://www.adslgate.com/thread.php?t=2748886"} itemTitle={"التحول لنظام الماك بعد 15 سنة من ويندوز"} 
              direction={"rtl"} imgSource={"./Assets/Adslgate-Article.png"} imgAlt={"Adslgate-Article"} moreAttribute={"lazy"}/>

              <SectionItems link={"https://www.adslgate.com/thread.php?t=2748793"} itemTitle={"شريت ماك بوك برو بسعر مخفض وجميل"} 
              direction={"rtl"} imgSource={"./Assets/Adslgate-Article.png"} imgAlt={"Adslgate-Article"} moreAttribute={"lazy"}/>

            </Section>
            {/* My Writings Section End */}

            {/* Other Section */}
            <Section columns={1} columnsMd={1} sectionMarginBottom={20} sectionTitle={t('Other')} imgSource={'Assets/dots.png'} imgAlt={'Three Dots'} moreAttribute={"lazy"}>

              <SectionItems link={"https://mega.nz/folder/WeJCzQAZ#hTkiPCWDZHSRSPJetxHktA"} itemTitle={t("PC Wallpaper Pack")} 
              imgSource={"./Assets/pack.png"} imgAlt={"Gallery"} moreAttribute={"lazy"}/>

              <SectionItems link={"https://mega.nz/folder/rLYRhDiR#IntZV486iGaB4HnqYEf9EA"} itemTitle={t("Mobile Wallpaper Pack")} 
              imgSource={"./Assets/pack.png"} imgAlt={"Gallery"} moreAttribute={"lazy"}/>

            </Section>
            {/* Other Section END */}

            {/* Websites Section */}
            <Section columns={1} columnsMd={1} sectionMarginBottom={20} sectionTitle={t('Interesting Websites')} imgSource={'Assets/Weblink.png'} imgAlt={'Website'} moreAttribute={"lazy"}
            
            addtionToTitle={<small class={`${marginLorR} font-semibold text-gray-500 leading-snug block sm:inline text-base sm:text-xl`}>{t("I enjoy browsing")}</small>}>

            <SectionItems link={"https://www.notion.so/"} itemTitle={"Notion"} 
            imgSource={"./Assets/Notion.png"} imgAlt={"Notion"} moreAttribute={"lazy"}/>

            <SectionItems link={"https://www.adslgate.com/"} itemTitle={"Adslgate | البوابة الرقمية"} 
            imgSource={"./Assets/Adslgste.png"} imgAlt={"Adslgate"} moreAttribute={"lazy"}/>

            <SectionItems link={"https://saudiopensourcecommunity.github.io/SaudiOSS/"} itemTitle={"SaudiOSS | المصادر المفتوحة السعودية"} 
            imgSource={"./Assets/SSO.png"} imgAlt={"SaudiOSS"} moreAttribute={"lazy"}/>


            <SectionItems link={"https://monkeytype.com"} itemTitle={"MonkeyType"} 
            imgSource={"./Assets/Monkeytype.png"} imgAlt={"MonkeyType"} moreAttribute={"lazy"} moreStylingImg={"rounded-md"}/>

            <SectionItems link={"https://www.typingclub.com/sportal/program-3.game"} itemTitle={"Typing club"} 
            imgSource={"./Assets/Typingclub.png"} imgAlt={"Typing club"} moreAttribute={"lazy"}/>

            <SectionItems link={"https://coderhub.sa/"} itemTitle={"Coderhub"} 
            imgSource={"./Assets/Coderhub.png"} imgAlt={"Coderhub"} moreAttribute={"lazy"}/>

            <SectionItems link={"https://pricena.com/"} itemTitle={"Pricena | برايسنا"} 
            imgSource={"./Assets/Pricena.png"} imgAlt={"Pricena"} moreAttribute={"lazy"}/>

            <SectionItems link={"https://macosicons.com/#/"} itemTitle={"MacOS Icons"} 
            imgSource={"./Assets/MacOsIcons.jpeg"} imgAlt={"MacOS Icons"} moreStylingImg={"rounded-md"} moreAttribute={"lazy"}/>

            <SectionItems link={"https://alternativeto.net/"} itemTitle={"AlternativeTo"} 
            imgSource={"./Assets/AlternativeTo.png"} imgAlt={"AlternativeTo"} moreAttribute={"lazy"}/>

            <SectionItems link={"https://speedtest.net/"} itemTitle={"SpeedTest"} 
            imgSource={"./Assets/SpeedTest.jpeg"} imgAlt={"SpeedTest"} moreStylingImg={"rounded-md"} moreAttribute={"lazy"}/>

            <SectionItems link={"https://www.photopea.com/"} itemTitle={"Photopea"} 
            imgSource={"./Assets/Photopea.png"} imgAlt={"Photopea"} moreAttribute={"lazy"}/>

            <SectionItems link={"https://www.remove.bg/"} itemTitle={"Remove BG"} 
            imgSource={"./Assets/Remove Bg.png"} imgAlt={"Remove BG"} moreAttribute={"lazy"}/>



            </Section>
            {/* Websites Section END */}

            {/* My Setup things Section */}
            <Section columns={1} columnsMd={1} sectionMarginBottom={20} sectionTitle={t('My Setup Things')} imgSource={'./Assets/setup.png'} imgAlt={'Setup'} moreAttribute={"lazy"}>
            
              <SectionItems link={"https://www.samsung.com/sa/monitors/high-resolution/s80pb-32-32-inch-ips-uhd-hdr-ls32b800pxmxue/"} itemTitle={t("Monitor : Samsung ViewFinity S8 UHD 32 inch")} 
              imgSource={"./Assets/ViewFinity S8.png"} imgAlt={"Samsung ViewFinity S8"} moreAttribute={"lazy"}/>
            
              <SectionItems link={"https://www.keychron.com/products/keychron-m3-mini-wireless-mouse?variant=40473820758105"} itemTitle={t("Mouse : Keychron M3 Mini Wireless 1000Hz Mouse")} 
              imgSource={"./Assets/Keychron M3-2.png"} imgAlt={"Keychron M3 Mini Wireless"} moreAttribute={"lazy"}/>
            
              <SectionItems link={"https://s.click.aliexpress.com/e/_DEaNh7r"} itemTitle={t("Mouse : Keychron M3 Mini Wireless 1000Hz Mouse (AliExpress)")} 
              imgSource={"./Assets/Keychron M3-2.png"} imgAlt={"Keychron M3 Mini Wireless"} moreAttribute={"lazy"}/>
            
              <SectionItems link={"https://www.keychron.com/products/keychron-k2-pro-qmk-via-wireless-mechanical-keyboard?variant=40336733569113"} itemTitle={t("Keyboard : Keychron K2 Pro Brown Switch Wireless Mechanical Keyboard")} 
              imgSource={"./Assets/Keychron K2 Pro.png"} imgAlt={"Keychron K2 Pro"} moreAttribute={"lazy"}/>
            
              <SectionItems link={"https://s.click.aliexpress.com/e/_DeTj081"} itemTitle={t("Keyboard : Keychron K2 Pro Brown Switch Wireless Mechanical Keyboard (AliExpress)")} 
              imgSource={"./Assets/Keychron K2 Pro.png"} imgAlt={"Keychron K2 Pro"} moreAttribute={"lazy"}/>
            
              <SectionItems link={"https://ar.aliexpress.com/item/1005007268856032.html?spm=a2g0o.order_list.order_list_main.46.2e587385HpT1vO&gatewayAdapt=glo2ara"} itemTitle={t("Keyboard Arabic Keycaps")} 
              imgSource={"./Assets/Keycaps.png"} imgAlt={"Arabic Keycaps"} moreAttribute={"lazy"}/>
            
              <SectionItems link={"https://s.click.aliexpress.com/e/_DEXLa4Z"} itemTitle={t("Monitor Light Bar")} 
              imgSource={"./Assets/Monitor Light Bar.png"} imgAlt={"Monitor Light Bar"} moreAttribute={"lazy"}/>

            </Section>
            {/* My Setup things Section END */}

            {/* Contact Me Section */}
            <ContactSection/>
            {/* Contact Me Section END */}
            

          </main>
        </div>
      </div>
    </>
  )
}
