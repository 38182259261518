import { useTranslation } from 'react-i18next';

export default function SocialSection() {

  const { t } = useTranslation();

  function showMessageCV() {
      alert(t('CV last updated on July 30'));
      return false;
}

  return (
    <>

            {/* Social Section */}
            <div style={{fontFamily: "system-ui"}} className='flex flex-wrap justify-center gap-1.5 mt-8' dir='ltr'>

                {/* Twitter */}
                <a href="http://twitter.com/abdullah_dev115" target="_blank" rel="noreferrer" className="delayed-link">
                  <div className="bg-gray-700 text-gray-300 hover:bg-gray-300 hover:text-gray-900 hover:scale-110 transition-all inline-flex
                  px-2 sm:px-2.5 py-1.5 sm:py-1.5 rounded-md group">
                    <div className="inline-flex items-center">
                      <img className="w-3 h-3 sm:w-3.5 sm:h-3.5 mr-1 sm:mr-1.5 aspect-square filter grayscale group-hover:grayscale-0 group-hover:filter-none" width="64" height="64" alt="Twitter" src="../Assets/X-logo.png"/>
                    </div>
                    <span className="text-xs sm:text-sm">Twitter</span>
                  </div>
                </a>

                {/* LinkedIn */}

                <a href="https://www.linkedin.com/in/an-almohsen/" target="_blank" rel="noreferrer" className="delayed-link">
                  <div className="bg-gray-700 text-gray-300 hover:bg-gray-300 hover:text-gray-900 hover:scale-110 transition-all inline-flex 
                  px-2 sm:px-2.5 py-1.5 sm:py-1.5 rounded-md group">
                    <div className="inline-flex items-center">
                      <img className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-1.5 aspect-square filter grayscale group-hover:grayscale-0 group-hover:filter-none" width="64" height="64" alt="LinkedIn" src="./Assets/LinkedIn.png"/>
                    </div>
                    <span className="text-xs sm:text-sm">LinkedIn</span>
                  </div>
                </a>

                {/* Github */}
                <a href="http://github.com/Abdullah-Dev115" target="_blank" rel="noreferrer" className="delayed-link">
                  <div className="bg-gray-700 text-gray-300 hover:bg-gray-300 hover:text-gray-900 hover:scale-110 transition-all inline-flex 
                  px-2 sm:px-2.5 py-1.5 sm:py-1.5 rounded-md group">
                    <div className="inline-flex items-center">
                      <img className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-1.5 aspect-square filter grayscale group-hover:grayscale-0 group-hover:filter-none" width="64" height="64" alt="Github" src="./Assets/Github-W.png"/>
                    </div>
                    <span className="text-xs sm:text-sm">Github</span>
                  </div>
                </a>

                {/* CV */}
                <a href="./Assets/Abdullah Almohsen CV-F.pdf" target="_blank" rel="noreferrer" dir='rtl' onClick={showMessageCV} className="delayed-link">
                  <div className="bg-gradient-to-r from-violet-700 to-indigo-600 hover:bg-gray-300 hover:scale-110 transition-all text-xxs font-medium sm:text-sm md:text-sm lg:text-sm lg:font-medium inline-flex items-start
                  px-2 sm:px-2.5 py-1.5 sm:py-1.5 rounded-md group">
                    <span className="text-xs sm:text-sm font-medium">{t("Download CV")}</span>
                  </div>
                </a>

            </div>
            {/* Social Section End */}
    </>
  )
}
