import { useContext } from 'react'
import { LanguageContext } from './languageContext'

export default function Section({columns, columnsMd, moreAttribute, sectionMarginBottom, sectionTitle, addtionToTitle, imgSource, imgAlt, children}) {
  const { language } = useContext(LanguageContext);
  const marginLorR = language === "ar" ? "ml-2" : "mr-2"

  return (
    <>
      <section className={`mb-${sectionMarginBottom}`} dir={language}>
        <div className="container mx-auto">
          <div>
            <div className="flex flex-wrap items-center">
              <h2 className={`flex m-0 items-center font-extrabold text-2xl ml-2`}>
                <img src={imgSource} alt={imgAlt} className={`w-8 h-8 ${marginLorR}`} loading={moreAttribute} decoding="async"/>
                {sectionTitle}
              </h2>
              {addtionToTitle && (
                <div className="w-full sm:w-auto mt-2 sm:mt-0">
                  {addtionToTitle}
                </div>
              )}
            </div>
            <hr className="h-px my-4 border-0 bg-indigo-600"/>
          </div>
          <div className={`grid grid-cols-${columns} sm:grid-cols-${columnsMd} gap-1.5 sm:gap-2`}>
            {children}
          </div>
        </div>
      </section>
    </>
  )
}
// import { useContext } from 'react'
// import { LanguageContext } from './languageContext'

// export default function Section({columns, columnsMd, moreAttribute, sectionMarginBottom, sectionTitle, addtionToTitle, imgSource, imgAlt, children}) {


//   const { language } = useContext(LanguageContext);

//   const marginLorR = language === "ar" ? "ml-2" : "mr-2"

//   return (
//     <> 
//       <section className={`mb-${sectionMarginBottom}`} dir={language}>
//         <div className="container mx-auto">
//           <div>
//             <h2 className={`flex m-0 items-center font-extrabold text-2xl ml-2`}>
//               <img src={imgSource} alt={imgAlt} className={`w-8 h-8 ${marginLorR}`} loading={moreAttribute} decoding="async"/>
//               {sectionTitle} {addtionToTitle}
//             </h2>
//             <hr className="h-px my-4 border-0 bg-indigo-600"/>
//           </div>
          
//           <div className={`grid grid-cols-${columns} sm:grid-cols-${columnsMd} gap-1.5 sm:gap-2`}>
//             {children} 
//           </div>
//         </div>
//       </section>
//     </>
//   )
  
// }
