import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../languageContext';
import SocialSection from './SocialSection';
import CodeStatement from './CodeStatement';
import SwitchLanguage from './SwitchLanguage';

export default function Introducing({children}) {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();



  // function showMessageCV() {
  //   alert(t('CV last updated on July 30'));
  //   return false;
  // }

  // const handleLanguageChange = () => {
  //   const newLanguage = language === 'ar' ? 'en' : 'ar';
  //   setLanguage(newLanguage);
  // };



  return (
    <>
            {/* <div class="bg-blue-500 sm:bg-green-500 md:bg-yellow-500 lg:bg-red-500 xl:bg-purple-500">
              Responsive Box
            </div> */}

            <div div dir={language === 'ar' ? 'rtl' : 'ltr'} class="flex flex-col items-center justify-center text-center mt-11">
              <div class="Personal Info">
                  <h1 class="text-3xl sm:text-4xl font-extrabold m-0 mb-2 text-center">{t('Abdullah Al-Mohsen')}</h1>
                  <p class="text-slate-400">{t('A Software Engneering Student in Prince Sattam bin Abdulaziz University')}</p>
              </div>
            </div>


            <SocialSection/>

            <CodeStatement/>
            
            <SwitchLanguage/>

    </>
  )
}
