// import { useContext } from 'react'
// import { LanguageContext } from './languageContext'

export default function SectionWrapper({ moreStyling, children}) {
  // const { websiteDirection } = useContext(LanguageContext);

  return (
    <>
        <div className={`grid grid-cols-2 sm:grid-cols-3 gap-2 mb-20 mt-4 ${moreStyling}`}>
            {children} 
        </div>
    </>
  )
}
