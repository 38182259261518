import { useContext } from "react"
import { LanguageContext } from "./languageContext"

export default function SectionItems(
  {link, itemTitle, imgSource, moreAttribute, imgAlt, direction, moreStylingImg, contribution, children }) {
    const { language } = useContext(LanguageContext);

    const marginLorR = language === 'ar' ? 'mr-auto' : 'ml-auto'

  return (
    <>

    <div className={`grid grid-cols-1 md:grid-cols-1 gap-2 md:gap-4 mb-1`}>
    
      <a href={link} target="_blank" rel="noreferrer" className="delayed-link bg-slate-500 bg-opacity-10 hover:bg-indigo-400 hover:bg-opacity-10
      rounded-md ps-1 py-1 pe-2 hover:scale-110 transition-all group">
        <div className="flex flex-row justify-start items-center">
          <img className={`w-6 md:w-8 ${moreStylingImg} me-2 sm:me-3.5 aspect-square filter grayscale group-hover:grayscale-0 group-hover:filter-none ${moreStylingImg}`}
          alt={imgAlt} src={imgSource} loading={moreAttribute} decoding="async"/> 
          <h3 dir={`${direction}`} className="text-sm sm:text-base">{itemTitle}
            {contribution}
          </h3>

          <div className={`flex items-center ${marginLorR} filter grayscale group-hover:grayscale-0`}>
            {children}
          </div>
        </div>
      </a>



    </div>
    
    </>
  )
}
