import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom'
import './App.css';
import BodyAndHeader from './components/BodyAndHeader';
import { LanguageContext } from './components/languageContext';
import WinshutScheduler from './components/WinshutScheduler';
import WaLoAyah from './components/WaLoAyah';
import HeadTags from './components/HeadTags';

function App() {
  const [language, setLanguage] = useState('ar');
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('ar');
  }, [i18n]);

  const toggleLanguage = (newLang) => {
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
  };


  return (
    <>
      <HeadTags/>
      
      <LanguageContext.Provider value={{
        language,
        direction: language === "ar" ? "rtl" : "ltr",
        setLanguage: toggleLanguage,
      }}>
        <div dir={language === "ar" ? "rtl" : "ltr"}>
          <Routes>
            <Route path="/" element={<BodyAndHeader />} />
            <Route path="/WinshutScheduler" element={<WinshutScheduler />} />
            <Route path="/WaLoAyah" element={<WaLoAyah />} />
          </Routes>
        </div>
      </LanguageContext.Provider>
    </>
  );
}

export default App;